<template>
  <main>
    <!-- Intro -->
    <catalog-shop-intro
      :size="size"
      :shop="shop"
    />

    <!-- Views -->
    <router-view :key="service" />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'

import { default as CatalogShopIntro } from '@/modules/catalog/components/catalog/intro'

export default {
  name: 'CatalogShopView',

  components: {
    CatalogShopIntro
  },

  inject: [
    '$embed',
    '$challenge',
  ],

  computed: {
    ...mapGetters({
      oat: 'bootstrap/oat',
    }),

    isChallengeEnabled() {
      return this.$basil.get(this.oat, 'is_challenge_enabled', false)
    },

    service() {
      return this.$route.params.service
    },

    shop() {
      return this.$basil.get(this.$embed, 'shop.model')
    },

    size() {
      return this.service ? 's' : 'm'
    }
  },

  mounted() {
    if(this.isChallengeEnabled) {
      this.$challenge.getChallenges()
        .catch((e) => $console.error(e))
    }
  }
}
</script>
